<template>
  <wrapper>
    <app-header/>
    <div class="schedule" v-if="projectId">
      <container class="schedule__legend legend">
        <router-link class="schedule__legend-navigation" :to="{ name: 'EditProject', params: { id: projectId } }">
          Список пользователей
        </router-link>
        <div class="legend__wrapper">
          <div class="legend__item legend__item_green">
            Рабочий
          </div>
          <div class="legend__item legend__item_red">
            Экспресс
          </div>
          <div class="legend__item legend__item_orange">
            Больничный
          </div>
          <div class="legend__item legend__item_blue">
            Выходной
          </div>
          <div class="legend__item legend__item_purple">
            Ремонт
          </div>
          <div class="legend__item legend__item_yellow">
            Отпуск
          </div>
        </div>
      </container>
      <div class="schedule__info">
        <container class="schedule__preview preview">
            <div class="preview__skeleton" v-if="! userDownloaded">
              <div class="preview__skeleton-avatar skeleton-element"></div>
              <div class="preview__skeleton-wrapper">
                <div class="preview__skeleton-element skeleton-element"></div>
                <div class="preview__skeleton-element skeleton-element"></div>
                <div class="preview__skeleton-element skeleton-element"></div>
                <div class="preview__skeleton-element skeleton-element display-1200"></div>
                <div class="preview__skeleton-element skeleton-element display-1200"></div>
                <div class="preview__skeleton-element skeleton-element display-1200"></div>
              </div>
            </div>
            <div class="preview__wrapper" v-if="userDownloaded">
              <div
                  class="preview__navigation"
                  :class="{
                    'preview__navigation_up' : showProfile,
                    'preview__navigation_down' : ! showProfile,
                  }"
                  @click="toggleProfile"
              >
                Редактировать профиль
              </div>
              <div class="preview__header container-header">
                {{ displayRole }}
              </div>
              <div class="preview__info">
                <img class="preview__avatar" :src="getAvatar(avatar)" alt="Аватар">
                <div class="preview__user-data">
                  <div class="preview__info-box">
                    <div class="preview__caption">
                      Фамилия Имя Отчество
                    </div>
                    <div class="preview__data">
                      {{ fullUserName }}
                    </div>
                  </div>
                  <div class="preview__info-box">
                    <div class="preview__caption">
                      Телефон
                    </div>
                    <div class="preview__data">
                      {{ phone || 'Не указано' }}
                    </div>
                  </div>
                  <div class="preview__info-box">
                    <div class="preview__caption">
                      E-mail
                    </div>
                    <div class="preview__data">
                      {{ email || 'Не указано' }}
                    </div>
                  </div>
                  <div class="preview__info-box" v-if="role === 'driver'">
                    <div class="preview__caption">
                      Въезд в ТТК
                    </div>
                    <div class="preview__data">
                      {{ allowedTtk ? 'Да' : 'Нет' }}
                    </div>
                  </div>
                  <div class="preview__info-box" v-if="role === 'driver'">
                    <div class="preview__caption">
                      Время отгрузки
                    </div>
                    <div class="preview__data">
                      {{ disembarkTime || 'Не указано' }}
                    </div>
                  </div>
                  <div class="preview__info-box" v-if="role === 'driver'">
                    <div class="preview__caption">
                      Размер будки (ВxДxШ)
                    </div>
                    <div class="preview__data">
                      {{ carBoothSize || 'Не указано' }}
                    </div>
                  </div>
                  <div class="preview__info-box" v-if="role === 'driver'">
                    <div class="preview__caption">
                      Грузоподъемность (кг)
                    </div>
                    <div class="preview__data">
                      {{ vehicleLoadCapacity || 'Не указано' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </container>
        <div class="schedule__workspace">
          <container class="schedule__requests requests">
            <div class="requests__skeleton" v-if="! requestsDownloaded">
              <div class="requests__skeleton-header skeleton-element"></div>
              <div class="requests__skeleton-element skeleton-element"></div>
              <div class="requests__skeleton-element skeleton-element"></div>
              <div class="requests__skeleton-element skeleton-element"></div>
              <div class="requests__skeleton-element skeleton-element"></div>
              <div class="requests__skeleton-element skeleton-element"></div>
            </div>
            <div class="requests__wrapper" v-if="requestsDownloaded">
              <div class="requests__header container-header">
                Заявки от сотрудника
              </div>
              <div class="requests__data-container requests__data-container_header">
                <div class="requests__data-cell requests__data-cell_header">
                  Заявка
                </div>
                <div class="requests__data-cell requests__data-cell_header">
                  Подтверждение
                </div>
              </div>
              <div class="requests__data-container" v-for="request in requests" :key="request.id">
                <div class="requests__data-cell requests__request"
                     @click="downloadEmployeesQuantity(request.issuedDay)" @mouseenter="downloadEmployeesQuantity(request.issuedDay)">
                  <div class="requests__date">
                    {{ request.issuedDay }}
                  </div>
                  <div class="requests__schedule">
                    {{ request.scheduleStatus }}
                  </div>
                  <div class="requests__hint">
                    <div class="request__skeleton" v-if="! employeesQuantityDownloaded || ! statusesDownloaded">
                      <div class="request__skeleton-element skeleton-element"></div>
                    </div>
                    <div class="requests__hint-wrapper" v-if="employeesQuantityDownloaded && statusesDownloaded">
                      {{ employeesQuantity }} Сотрудников
                    </div>
                  </div>
                </div>
                <div class="requests__data-cell requests__button-group">
                  <button class="request__button" @click="processScheduleRequest(request.id, 'approve')">
                    Да
                  </button>
                  <button class="request__button" @click="processScheduleRequest(request.id, 'reject')">
                    Нет
                  </button>
                </div>
              </div>
            </div>
          </container>
          <container class="schedule__calendar calendar">
            <div class="calendar__skeleton" v-if="! scheduleDownloaded">
              <div class="calendar__skeleton-header skeleton-element"></div>
              <div class="calendar__skeleton-element skeleton-element"></div>
            </div>
            <div class="calendar__wrapper" v-if="scheduleDownloaded">
              <div class="calendar__header container-header">
                Рабочий календарь
              </div>
              <v-date-picker
                  class="calendar__datepicker" mode="date" is-expanded
                  v-model="calendarDate" :attributes="calendarAttributes" :select-attribute="{ bar: 'gray' }"
                  @dayclick="setDate"
              />
            </div>
          </container>
          <container class="schedule__control control">
            <div class="control__skeleton" v-if="! statusesDownloaded">
              <div class="control__skeleton-element skeleton-element"></div>
              <div class="control__skeleton-element skeleton-element"></div>
              <div class="control__skeleton-element skeleton-element"></div>
              <div class="control__skeleton-element skeleton-element"></div>
            </div>
            <div class="control__wrapper" v-if="statusesDownloaded">
              <div class="control__header container-header">
                Изменить график
              </div>
              <form action="#" method="post" class="control__form">
                <div class="control__form-input-box">
                  <app-input
                      class="control__element" id="dateStart" label="Дата (От)" readonly
                      v-model:model-value="dateStart"
                      :input-class-list="[{ 'form-element-error' : fieldHasError('date_start') }]"
                      @focus="clearError"
                  />
                  <span class="form-caption-error" v-show="fieldHasError('date_start')" v-html="errorBag.date_start"></span>
                </div>
                <div class="control__form-input-box">
                  <app-input
                      class="control__element" id="dateEnd" label="Дата (До)" readonly
                      v-model:model-value="dateEnd"
                      :input-class-list="[{ 'form-element-error' : fieldHasError('date_end') }]"
                      @focus="clearError"
                  />
                  <span class="form-caption-error" v-show="fieldHasError('date_end')" v-html="errorBag.date_end"></span>
                </div>
                <div class="control__form-select-box">
                  <app-select
                      class="control__element" id="statusId" label="Тип"
                      :options="statuses" v-model:model-value="statusId"
                      :select-class-list="[{ 'form-element-error' : fieldHasError('status_id') }]"
                      @update="updateData" @focus="clearError"
                  />
                  <span class="form-caption-error" v-show="fieldHasError('status_id')" v-html="errorBag.status_id"></span>
                </div>
                <app-button class="control__button" button-style="button_action" @click.prevent="updateSchedule">
                  Изменить
                </app-button>
              </form>
            </div>
          </container>
        </div>
      </div>
    </div>
    <container class="user" v-show="showProfile">
      <div class="user__skeleton skeleton" v-if="! userDownloaded">
        <div class="skeleton__header skeleton-element"></div>
        <div class="skeleton__info-container">
          <div class="skeleton__info skeleton-element"></div>
          <div class="skeleton__info skeleton-element"></div>
          <div class="skeleton__info skeleton-element"></div>
          <div class="skeleton__info skeleton-element"></div>
          <div class="skeleton__info skeleton-element"></div>
          <div class="skeleton__info skeleton-element"></div>
          <div class="skeleton__info skeleton-element"></div>
          <div class="skeleton__info skeleton-element"></div>
          <div class="skeleton__info skeleton-element"></div>
        </div>
        <div class="skeleton__header skeleton-element"></div>
        <div class="skeleton__password-container">
          <div class="skeleton__password skeleton-element"></div>
          <div class="skeleton__password skeleton-element"></div>
        </div>
      </div>
      <div class="user__wrapper" v-if="userDownloaded">
        <form action="#" method="post" class="user__form">
          <div class="user__header">
            <div class="container-header">
              Общие данные
            </div>
            <router-link :to="{ name: 'Users' }" class="user__previous-page" v-if="! projectId">
              К списку пользователей
            </router-link>
          </div>
          <div class="user__form-group">
            <div class="user__form-element-box">
              <app-input
                  class="user__input"  id="lastName" label="Фамилия" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('last_name') }]"
                  v-model:model-value="lastName"
                  @erase="clearData" @focus="clearError"
              />
              <span class="user__form-error-description form-caption-error" v-show="fieldHasError('last_name')" v-html="errorBag.last_name"></span>
            </div>
            <div class="user__form-element-box">
              <app-input
                  class="user__input"  id="firstName" label="Имя" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('first_name') }]"
                  v-model:model-value="firstName"
                  @erase="clearData" @focus="clearError"
              />
              <span class="user__form-error-description form-caption-error" v-show="fieldHasError('first_name')" v-html="errorBag.first_name"></span>
            </div>
            <div class="user__form-element-box">
              <app-input
                  class="user__input"  id="patronymic" label="Отчество" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('patronymic') }]"
                  v-model:model-value="patronymic"
                  @erase="clearData" @focus="clearError"
              />
              <span class="user__form-error-description form-caption-error" v-show="fieldHasError('patronymic')" v-html="errorBag.patronymic"></span>
            </div>
            <div class="user__form-element-box">
              <app-input
                  class="user__input"  id="login" label="Логин" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('login') }]"
                  v-model:model-value="login"
                  @erase="clearData" @focus="clearError"
              />
              <span class="user__form-error-description form-caption-error" v-show="fieldHasError('login')" v-html="errorBag.login"></span>
            </div>
            <div class="user__form-element-box">
              <app-input
                  class="user__input"  id="email" label="E-mail" type="email" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('email') }]"
                  v-model:model-value="email"
                  @erase="clearData" @focus="clearError"
              />
              <span class="user__form-error-description form-caption-error" v-show="fieldHasError('email')" v-html="errorBag.email"></span>
            </div>
            <div class="user__form-element-box">
              <app-input
                  class="user__input"  id="phone" label="Телефон" type="tel" mask="+7 (###) ###-##-##" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('phone') }]"
                  v-model:model-value="phone"
                  @erase="clearData" @focus="clearError"
              />
              <span class="user__form-error-description form-caption-error" v-show="fieldHasError('phone')" v-html="errorBag.phone"></span>
            </div>
            <div class="user__form-element-box" v-if="employeeFormElementShown">
              <app-input
                  class="user__input"  id="disembarkTime" label="Предпочитаемое время отгрузки" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('disembark_time') }]"
                  v-model:model-value="disembarkTime"
                  @erase="clearData" @focus="clearError"
              />
              <span class="user__form-error-description form-caption-error" v-show="fieldHasError('disembark_time')" v-html="errorBag.disembark_time"></span>
            </div>
            <div class="user__form-element-box" v-if="employeeFormElementShown">
              <app-input
                  class="user__input"  id="carBoothSize" label="Размер будки (ВхГхШ)" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('car_booth_size') }]"
                  v-model:model-value="carBoothSize"
                  @erase="clearData" @focus="clearError"
              />
              <span class="user__form-error-description form-caption-error" v-show="fieldHasError('car_booth_size')" v-html="errorBag.car_booth_size"></span>
            </div>
            <div class="user__form-element-box" v-if="employeeFormElementShown">
              <app-input
                  class="user__input"  id="vehicleLoadCapacity" label="Грузоподъмность машины (кг)" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('vehicle_load_capacity') }]"
                  v-model:model-value="vehicleLoadCapacity"
                  @erase="clearData" @focus="clearError"
              />
              <span class="user__form-error-description form-caption-error" v-show="fieldHasError('vehicle_load_capacity')" v-html="errorBag.vehicle_load_capacity"></span>
            </div>
            <div class="user__form-element-box" v-if="employeeFormElementShown">
              <app-select
                  class="user__input" id="allowedTtk" label="Въезд в ТТК" enable-erase
                  :options="[
                {
                  key: 'yes',
                  value: true,
                  display: 'Да',
                  selected: this.allowedTtk === true
                },
                {
                  key: 'no',
                  value: false,
                  display: 'Нет',
                  selected: this.allowedTtk === false
                }
              ]"
                  :select-class-list="[{ 'form-element-error' : fieldHasError('allowed_ttk') }]"
                  @update="updateData" @focus="clearError"
              />
              <span class="user__form-error-description form-caption-error" v-show="fieldHasError('allowed_ttk')"  v-html="errorBag.allowed_ttk"></span>
            </div>
            <div class="user__form-element-box" v-if="rolesDownloaded">
              <app-select
                  class="user__input" id="roleId" label="Роль"
                  :options="roles" v-model:model-value="roleId"
                  :select-class-list="[{ 'form-element-error' : fieldHasError('role_id') }]"
                  @update="(event) => { roleId = event.value }" @focus="clearError"
              />
              <span class="user__form-error-description form-caption-error" v-show="fieldHasError('role_id')"  v-html="errorBag.role_id"></span>
            </div>
            <div class="user__form-element-box user__form-address-element-box">
              <app-input
                  class="user__input user__input-address"  id="address" label="Адрес проживания" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('address') }]"
                  v-model:model-value="address"
                  @erase="clearData" @focus="clearError"
              />
              <app-text-area
                  class="user__text-area-container"  id="address" label="Адрес проживания" enable-erase
                  :text-area-class-list="['user__text-area']" :input-class-list="[{ 'form-element-error' : fieldHasError('address') }]"
                  v-model:model-value="address"
                  @erase="clearData" @focus="clearError"
              ></app-text-area>
              <span class="user__form-error-description form-caption-error" v-show="fieldHasError('address')" v-html="errorBag.address"></span>
            </div>
          </div>
          <div class="user__header container-header">
            Смена пароля
          </div>
          <div class="user__form-group">
            <div class="user__form-element-box user__form-password-element-box">
              <app-input
                  class="user__input user__input-password"  id="password" label="Новый пароль" type="password" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('password') }]"
                  v-model:model-value="password"
                  @erase="clearData" @focus="clearError"
              />
              <span class="user__form-error-description form-caption-error" v-show="fieldHasError('password')" v-html="errorBag.password"></span>
            </div>
            <div class="user__form-element-box user__form-password-element-box">
              <app-input
                  class="user__input user__input-password"  id="passwordConfirmation" label="Подтвердить новый пароль" type="password" enable-erase
                  v-model:model-value="passwordConfirmation"
                  @erase="clearData"
              />
            </div>
          </div>
          <div class="user__control-group">
            <div class="user__action-group"></div>
            <div class="user__button-group">
              <app-button class="user__button" is-submit @click.prevent="updateUser">
                Сохранить
              </app-button>
              <app-button button-style="button_cancel" class="user__button" @click="eraseData">
                Сбросить
              </app-button>
            </div>
          </div>
        </form>
      </div>
    </container>
  </wrapper>
</template>

<script>
import Wrapper from '@/layouts/Wrapper'
import AppHeader from '@/components/AppHeader'
import Container from '@/layouts/Container'
import AppInput from '@/components/AppInput'
import AppSelect from '@/components/AppSelect'
import AppButton from '@/components/AppButton'
import AppTextArea from '@/components/AppTextArea'
import { get as getUser, update as updateUser, count as countUsers } from '@/api/users'
import { statuses, userRequests, userSchedule, approve, reject, update as updateSchedule } from '@/api/schedule'
import { list as listRoles } from "@/api/roles";
import { isError } from '@/utils/request'
import { camelToSnake, parseDateToClassicString, parseDateToIsoString, scheduleStatusColor } from '@/utils/helpers'
import { deserialize } from 'deserialize-json-api'

export default {
  name: 'EditUser',
  components: {
    Wrapper,
    AppHeader,
    Container,
    AppInput,
    AppSelect,
    AppButton,
    AppTextArea
  },
  props: {
    projectId: String,
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      avatar: null,
      lastName: '',
      firstName: '',
      patronymic: '',
      login: '',
      email: '',
      phone: '',
      disembarkTime: '',
      carBoothSize: '',
      vehicleLoadCapacity: '',
      allowedTtk: '',
      address: '',
      password: '',
      passwordConfirmation: '',
      role: '',
      displayRole: '',
      dateStart: null,
      dateEnd: null,
      nextDate: 'first',
      employeesQuantity: null,
      statusesDownloaded: false,
      rolesDownloaded: false,
      scheduleDownloaded: false,
      requestsDownloaded: false,
      userDownloaded: false,
      employeesQuantityDownloaded: false,
      showProfile: false,
      statusId: null,
      statuses: [],
      roleId: null,
      roles: [],
      requests: [],
      calendarDate: null,
      calendarAttributes: [],
      errorBag: {}
    }
  },
  methods: {
    getAvatar(path) {
      return path || require('@/assets/svg/avatar.svg')
    },

    clearData(field) {
      this[field] = null
    },

    updateData(event) {
      this[event.field] = event.value
    },

    eraseData() {
      this.lastName = ''
      this.firstName = ''
      this.patronymic = ''
      this.login = ''
      this.email = ''
      this.phone = ''
      this.disembarkTime = ''
      this.carBoothSize = ''
      this.vehicleLoadCapacity = ''
      this.address = ''
    },

    fieldHasError(field) {
      return Object.prototype.hasOwnProperty.call(this.errorBag, field)
    },

    clearError(event) {
      const field = camelToSnake(event.target.id)

      if (Object.prototype.hasOwnProperty.call(this.errorBag, field)) {
        delete this.errorBag[field]
      }
    },

    toggleProfile() {
      this.showProfile = ! this.showProfile
    },

    setDate(day) {
      const date = parseDateToClassicString(day.date)

      if (this.nextDate === 'first') {
        this.dateStart = date
      } else {
        this.dateEnd = date
      }

      this.nextDate = this.nextDate === 'first' ? 'second' : 'first'
    },

    async getUser() {
      const response = await getUser(this.$store.state.user.token, this.userId)

      if (isError(response.status)) {
        this.$notify({
          type: 'error',
          text: response.data.errors.title
        })

        return
      }

      const userData = deserialize(response.data).data

      this.avatar = userData.avatar
      this.roleId = userData.role_id
      this.lastName = userData.last_name
      this.firstName = userData.first_name
      this.patronymic = userData.patronymic
      this.login = userData.login
      this.email = userData.email
      this.phone = userData.extra.phone
      this.allowedTtk = userData.extra.allowed_ttk
      this.disembarkTime = userData.extra.disembark_time
      this.carBoothSize = userData.extra.car_booth_size
      this.vehicleLoadCapacity = userData.extra.vehicle_load_capacity
      this.address = userData.extra.address
      this.role = userData.role.slug
      this.displayRole = userData.role.name
      this.userDownloaded = true
    },

    async updateUser() {
      this.errorBag = {}

      const data = {
        role_id: this.roleId,
        first_name: this.firstName,
        last_name: this.lastName,
        patronymic: this.patronymic,
        login: this.login,
        email: this.email,
        phone: this.phone,
        vehicle_load_capacity: this.vehicleLoadCapacity,
        car_booth_size: this.carBoothSize,
        address: this.address,
        disembark_time: this.disembarkTime,
        allowed_ttk: this.allowedTtk
      }

      if (this.password !== '' && this.password !== null) {
        data.password = this.password
        data.password_confirmation = this.passwordConfirmation
      }

      const response = await updateUser(this.$store.state.user.token, this.userId, data)

      if (isError(response.status)) {
        if (response.status === 422) {
          for (const [key, value] of Object.entries(response.data.errors.validation)) {
            for (const error of value) {
              const curKey = key.replace('data.', '')

              if (Object.prototype.hasOwnProperty.call(this.errorBag, curKey)) {
                this.errorBag[curKey] += `${error}<br>`
              } else {
                this.errorBag[curKey] = `${error}<br>`
              }
            }
          }
        } else {
          this.$notify({
            type: 'error',
            text: response.data.errors.title
          })
        }
      } else {
        this.$notify({
          type: 'success',
          text: response.data.meta.message
        })
      }

      this.password = ''
      this.password_confirmation = ''
    },

    async downloadStatuses() {
      this.statusesDownloaded = false
      this.statuses = []

      const response = await statuses(this.$store.state.user.token)

      if (isError(response.status)) {
        return
      }

      deserialize(response.data).data.forEach((status, index) => {
        this.statuses.push({
          key: parseInt(status.id),
          value: parseInt(status.id),
          slug: status.slug,
          display: status.name,
          selected: index === 0
        })

        if (index === 0) {
          this.statusId = parseInt(status.id)
        }
      })

      this.statusesDownloaded = true
    },

    async downloadRoles() {
      const response = await listRoles(this.$store.state.user.token)

      if (isError(response.status)) {
        return
      }

      let validRoles = []

      if (this.$store.state.user.profile.role.slug === 'super_admin') {
        validRoles = ['project_admin', 'driver', 'collector']
      } else if (this.$store.state.user.profile.role.slug === 'project_admin') {
        validRoles = ['driver', 'collector']
      }

      deserialize(response.data).data
        .filter((role) => validRoles.indexOf(role.slug) !== -1)
        .forEach((role) => {
          const roleId = parseInt(role.id)

          this.roles.push({
            key: roleId,
            value: roleId,
            display: role.name,
            selected: this.roleId === roleId
          })
        })

      this.rolesDownloaded = true
    },

    async downloadSchedule() {
      this.scheduleDownloaded = false
      this.calendarAttributes = []

      let response = await userSchedule(this.$store.state.user.token, this.userId)

      if (! isError(response.status)) {
        deserialize(response.data).data.forEach((scheduleDate) => {
          this.calendarAttributes.push({
            highlight: scheduleStatusColor(scheduleDate.status.slug),
            dates: scheduleDate.day
          })
        })
      }

      response = await userRequests(this.$store.state.user.token, this.userId, 'opened')

      if (! isError(response.status)) {
        deserialize(response.data).data.forEach((scheduleRequest) => {
          this.calendarAttributes.push({
            dot: scheduleStatusColor(scheduleRequest.schedule_status.slug),
            dates: scheduleRequest.issued_day
          })
        })
      }

      this.scheduleDownloaded = true
    },

    async downloadRequests() {
      this.requestsDownloaded = false
      this.requests = []

      const response = await userRequests(this.$store.state.user.token, this.userId, 'opened', '-created_at', 5)

      if (isError(response.status)) {
        return
      }

      deserialize(response.data).data.forEach((request) => {
        this.requests.push({
          id: parseInt(request.id),
          issuedDay: parseDateToClassicString(new Date(request.issued_day)),
          status: request.status,
          scheduleStatus: request.schedule_status.name
        })
      })

      this.requestsDownloaded = true
    },

    async processScheduleRequest(id, type) {
      let response

      if (type === 'approve') {
        response = await approve(this.$store.state.user.token, id)
      } else if (type === 'reject') {
        response = await reject(this.$store.state.user.token, id)
      }

      if (isError(response.status)) {
        this.$notify({
          type: 'error',
          text: response.data.errors.title
        })
      } else {
        this.$notify({
          type: 'success',
          text: response.data.meta.message
        })

        this.downloadRequests()
        this.downloadSchedule()
        this.$store.dispatch('user/updateProject')
      }
    },

    async updateSchedule() {
      const response = await updateSchedule(this.$store.state.user.token, this.userId, {
        status_id: this.statusId,
        date_start: this.dateStart,
        date_end: this.dateEnd
      })

      if (isError(response.status)) {
        if (response.status === 422) {
          for (const [key, value] of Object.entries(response.data.errors.validation)) {
            for (const error of value) {
              const curKey = key.replace('data.', '')

              if (Object.prototype.hasOwnProperty.call(this.errorBag, curKey)) {
                this.errorBag[curKey] += `${error}<br>`
              } else {
                this.errorBag[curKey] = `${error}<br>`
              }
            }
          }
        } else {
          this.$notify({
            type: 'error',
            text: response.data.errors.title
          })
        }
      } else {
        this.$notify({
          type: 'success',
          text: response.data.meta.message
        })

        this.downloadSchedule()
      }
    },

    async downloadEmployeesQuantity(day) {
      this.employeesQuantityDownloaded = false
      this.employeesQuantity = null

      const response = await countUsers(
          this.$store.state.user.token,
          this.projectId,
          this.statuses.filter((status) => status.slug === 'work')[0].slug,
          parseDateToIsoString(day)
      )

      if (isError(response.status)) {
        this.$notify({
          type: 'error',
          text: response.data.errors.title
        })
      } else {
        this.employeesQuantity = response.data.data.employees
        this.employeesQuantityDownloaded = true
      }
    }
  },
  computed: {
    employeeFormElementShown() {
      return this.role === 'driver'
    },

    fullUserName() {
      return `${this.lastName} ${this.firstName} ${this.patronymic}`
    }
  },
  async created() {
    if (! this.projectId) {
      this.showProfile = true
    }

    this.downloadStatuses()
    this.downloadSchedule()
    this.downloadRequests()
    await this.getUser()
    this.downloadRoles()
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

/* legend */
.schedule__legend {
  margin-bottom: 14px;
}

.schedule__legend-navigation {
  display: block;
  margin-bottom: 15px;
}

.legend__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.legend__item {
  font-size: 12px;
  color: #666;

  &::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 2px;
    border-radius: 100%;
  }

  &_green::before {
    background-color: #38a169;
  }

  &_red::before {
    background-color: #e53e3e;
  }

  &_orange::before {
    background-color: #dd6b20;
  }

  &_blue::before {
    background-color: #3182ce;
  }

  &_purple::before {
    background-color: #805ad5;
  }

  &_grey::before {
    background-color: #718096;
  }

  &_yellow::before {
    background-color: #d69e2e;
  }
}

.schedule__info, .schedule__workspace {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.schedule__legend, .preview, .requests, .calendar, .control {
  padding: 25px 20px 20px;
}

/* preview */
.schedule__preview {
  position: relative;
}

.preview__header {
  position: absolute;
  top: 70px;
  left: 90px;
}

.preview__avatar {
  display: block;
  width: 50px;
  margin: 30px 0 20px;
  border-radius: $border-radius;
}

.preview__user-data {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.preview__info-box {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.preview__caption {
  font-size: 13px;
  color: $label-text-color;
}

.preview__data {
  font-size: 14px;
  line-height: 1.5;
}

/* requests */
.requests__header {
  margin-bottom: 20px;
}

.requests__data-container {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 25px;
  }
}

.requests__data-cell {
  flex-basis: 50%;
}

.requests__request {
  position: relative;
  cursor: pointer;
}

.requests__hint {
  display: none;
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
  height: 50px;
  font-size: 15px;
  line-height: 50px;
  text-align: center;
  color: #444;
  background-color: #fff;
  border: 1px solid $separator-color;
  border-radius: $border-radius;
  z-index: 999;
}

.requests__request:hover .requests__hint {
  display: block;
}

.requests__date {
  margin-bottom: 5px;
}

.requests__data-container_header {
  font-size: 13px;
}

.requests__date {
  font-size: 14px;
}

.requests__schedule {
  font-size: 12px;
  color: $caption-text-color;
}

.requests__button-group {
  display: flex;
  gap: 8px;
}

.request__button {
  appearance: none;
  flex-grow: 1;
  height: 46px;
  font-size: 16px;
  color: #000;
  background-color: #fcfcfc;
  border: 1px solid $separator-color;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: inherit;
  }
}

/* calendar */
.calendar__header {
  margin-bottom: 20px;
}

.calendar__datepicker {
  border: none;
}

/* control */
.control__header {
  margin-bottom: 20px;
}

.control__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.control__element {
  width: 100%;
}

/* user */
.user {
  margin-top: 14px;
}

.user__form {
  padding: 25px 20px 20px;
}

.user__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.user__form-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.user__form-group:not(:last-child) {
  margin-bottom: 25px;
}

.user__form-element-box {
  flex-basis: 100%;
}

.user__input {
  width: 100%;
}

.user__form-error-description {
  display: block;
  margin-top: 10px;
}

.user__text-area-container {
  width: 100%;
}

.user__input-address {
  display: none;
}

.user__control-group {
  display: flex;
  flex-direction: column;
}

.user__action-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 25px;
}

.user__is-notifiable {
  max-width: 214px;
}

.user__upload-avatar {
  position: relative;
  color: #333;
  cursor: pointer;

  &::before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 20px;
    background-image: url("../assets/svg/upload.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.user__upload-avatar-caption {
  display: inline-block;
  max-width: 100px;
  margin-left: 10px;
  font-size: 13px;
}

.user__button-group {
  display: flex;
  justify-content: space-between;
}

.user__button {
  flex-basis: 47.5%;
}

.preview__navigation, .user__previous-page, .schedule__legend-navigation {
  font-size: 12px;
  color: $primary-links-color;
  cursor: pointer;

  &::before, &::after {
    content: "";
    position: absolute;
    right: 0;
    width: 6px;
    border-bottom: 1px solid $primary-links-color;
  }
}

.preview__navigation {
  position: absolute;
  top: 20px;
  left: 20px;
  padding-right: 12px;

  &::before, &::after {
    top: 7px;
  }
}

.preview__navigation_up {
  &::before {
    right: 0;
    transform: rotate(45deg);
  }

  &::after {
    right: 3.5px;
    transform: rotate(-45deg);
  }
}

.preview__navigation_down {
  &::before {
    right: 0;
    transform: rotate(-45deg);
  }

  &::after {
    right: 3.5px;
    transform: rotate(45deg);
  }
}

.user__previous-page, .schedule__legend-navigation {
  position: relative;
  padding-left: 12px;

  &::before {
    top: 9px;
    left: 0;
    transform: rotate(45deg);
  }

  &::after {
    top: 5.5px;
    left: 0;
    transform: rotate(-45deg);
  }
}

.display-1200 {
  display: none;
}

/* skeleton */
.skeleton {
  padding: 25px 20px 20px;
}

.skeleton__header {
  width: 127px;
  height: 26px;
  margin-bottom: 20px;
}

.skeleton__info-container, .skeleton__password-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.skeleton__info-container {
  margin-bottom: 25px;
}

.skeleton__info, .skeleton__password {
  width: 100%;
  height: 50px;
}

.requests__skeleton-header, .calendar__skeleton-header {
  display: inline-block;
  width: 127px;
  height: 26px;
  margin-bottom: 20px;
}

.requests__skeleton-element, .control__skeleton-element {
  flex-grow: 1;
  height: 50px;
}

.calendar__skeleton-element {
  width: 100%;
  height: 165px;
}

.requests__skeleton, .control__skeleton, .preview__skeleton-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.preview__skeleton-avatar {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.preview__skeleton-element {
  height: 24px;
}

.request__skeleton {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 15px;
}

.request__skeleton-element {
  width: 100%;
  height: 12px;
}

@media screen and (min-width: 576px) {
  /* legend */
  .schedule__legend {
    margin-bottom: 28px;
  }

  .schedule__legend-navigation {
    margin-bottom: 23px;
  }

  .legend__wrapper {
    gap: 25px;
  }

  .legend__item {
    font-size: 14px;

    &::before {
      width: 14px;
      height: 14px;
    }
  }

  .schedule__info, .schedule__workspace {
    gap: 28px;
  }

  .schedule__workspace {
    flex-direction: row;
    flex-wrap: wrap;
  }

  /* preview */
  .preview__navigation {
    left: auto;
    top: 27px;
    right: 20px;
  }

  .preview__header {
    position: static;
    margin-bottom: 25px;
  }

  .preview__info {
    display: flex;
    align-items: flex-start;
    gap: 13px;
  }

  .preview__avatar {
    flex-basis: 100px;
    flex-shrink: 0;
    margin: 0;
  }

  .preview__user-data {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px 23px;
  }

  .preview__data {
    font-size: 16px;
  }

  /* requests */
  .requests {
    flex-basis: calc(percentage(318 / 548) - 14px);
    order: 1;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .requests__header {
    margin-bottom: 25px;
  }

  .requests__data-container_header {
    display: none;
  }

  .requests__data-cell:first-child {
    margin-bottom: 5px;
  }

  .requests__date {
    font-size: 16px;
  }

  /* calendar */
  .calendar {
    flex-basis: 100%;
    order: 3;
  }

  .calendar__header {
    margin-bottom: 25px;
  }

  /* control */
  .control {
    flex-basis: calc(percentage(230 / 548) - 14px);
    order: 2;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  .control__header {
    margin-bottom: 25px;
  }

  .control__form {
    gap: 30px;
  }

  /* user */
  .user {
    margin-top: 28px;
  }

  .user__form {
    padding: 25px 20px;
  }

  .user__form-group {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .user__form-element-box {
    flex-basis: calc(50% - 10px);
  }

  .user__form-address-element-box {
    flex-basis: 100%;
  }

  .user__input-address {
    display: block;
  }

  .user__text-area-container {
    display: none;
  }

  .user__control-group {
    flex-direction: row;
    gap: 20px;
  }

  .user__action-group {
    margin: 0;
  }

  .user__action-group, .user__button-group {
    flex-basis: 50%;
  }

  .user__upload-avatar-caption {
    max-width: none;
    font-size: 15px;
  }

  .preview__navigation, .user__previous-page, .schedule__legend-navigation {
    font-size: 14px;
  }

  .preview__navigation {
    &::before, &::after {
      top: 8.5px;
    }
  }

  .user__previous-page, .schedule__legend-navigation {
    &::before {
      top: 11px;
    }

    &::after {
      top: 7.5px;
    }
  }

  /* skeleton */
  .skeleton {
    padding: 25px 20px;
  }

  .skeleton__info-container, .skeleton__password-container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .skeleton__info, .skeleton__password {
    flex-basis: calc(50% - 10px);
    height: 58px;
  }

  .calendar__skeleton-element {
    height: 340px;
  }

  .control__skeleton {
    gap: 30px;
  }

  .preview__skeleton {
    display: flex;
    gap: 20px;
  }

  .preview__skeleton-wrapper {
    flex-grow: 1;
    justify-content: space-between;
    gap: 0;
  }

  .preview__skeleton-avatar {
    width: 100px;
    height: 100px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  /* legend */
  .legend__wrapper {
    gap: 15px;
  }

  .schedule__legend, .preview, .requests, .calendar, .control {
    padding: 25px 20px;
  }

  /* preview */
  .preview__user-data {
    gap: 20px 30px;
  }

  /* requests */
  .requests {
    flex-basis: calc(percentage(357 / 964) - 14px);
    order: 1;
  }

  /* calendar */
  .calendar {
    flex-basis: calc(percentage(607 / 964) - 14px);
    order: 2;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  /* control */
  .control {
    flex-basis: 100%;
    order: 3;
    border-radius: 0;
  }

  .control__form {
    flex-direction: row;
    gap: 15px;
  }

  .control__form-input-box, .control__form-select-box, .control__button {
    flex-basis: calc(25% - (15px * 3 / 4));
  }

  /* user */
  .user__form-group {
    gap: 30px;
  }

  .user__form-group:not(:last-child) {
    margin-bottom: 35px;
  }

  .user__form-element-box {
    flex-basis: calc(50% - 15px);
  }

  .user__form-address-element-box {
    flex-basis: 100%;
  }

  .user__control-group {
    gap: 30px;
  }

  .user__action-group {
    gap: 20px;
  }

  .user__upload-avatar-caption {
    font-size: 16px;
  }

  .preview__navigation, .user__previous-page, .schedule__legend-navigation {
    font-size: 15px;
  }

  .preview__navigation {
    &::before, &::after {
      top: 9px;
    }
  }

  .user__previous-page, .schedule__legend-navigation {
    &::before {
      top: 11.5px;
    }

    &::after {
      top: 8px;
    }
  }

  /* skeleton */
  .skeleton__info, .skeleton__password {
    height: 68px;
  }

  .control__skeleton {
    flex-direction: row;
    gap: 15px;
  }
}

@media screen and (min-width: 992px) {
  /* legend */
  .schedule__legend {
    display: flex;
    padding: 25px 30px;
  }

  .schedule__legend-navigation {
    margin-bottom: 0;
  }

  .legend__wrapper {
    flex-grow: 1;
    justify-content: flex-end;
    gap: 14px;
  }

  .legend__item::before {
    margin-right: 5px;
  }

  .preview, .requests, .calendar, .control {
    padding: 25px 30px 30px;
  }

  /* preview */
  .preview__user-data {
    gap: 20px 40px;
  }

  /* requests */
  .requests__header {
    margin-bottom: 35px;
  }

  .requests__data-container_header {
    display: flex;
  }

  .requests__data-cell:first-child {
    margin-bottom: 0;
  }

  .requests__data-container_header {
    font-size: 15px;
  }

  /* calendar */

  /* control */
  .control__form {
    justify-content: space-between;
    gap: 30px;
  }

  /* user */
  .user__form {
    padding: 30px;
  }

  .user__form-element-box {
    flex-basis: calc(100% / 3 - 20px);
  }

  .user__form-password-element-box {
    flex-basis: calc(50% - 15px);
  }

  .user__form-address-element-box {
    flex-basis: 100%;
  }

  .user__action-group {
    flex-direction: row;
    gap: 30px;
  }

  .user__upload-avatar-caption {
    max-width: 100px;
  }

  .user__button-group {
    justify-content: right;
  }

  .user__button {
    flex-basis: 162px;
  }

  .user__button:not(:last-child) {
    margin-right: 30px;
  }

  /* skeleton */
  .skeleton {
    padding: 30px;
  }

  .skeleton__info {
    flex-basis: calc(100% / 3 - 20px);
  }

  .control__skeleton {
    gap: 30px;
  }
}

@media screen and (min-width: 1200px) {
  /* legend */
  .schedule__legend {
    padding: 25px 40px;
  }

  .legend__wrapper {
    gap: 40px;
  }

  .schedule__info {
    flex-direction: row;
  }

  .schedule__workspace {
    flex-basis: calc(percentage(917 / 1172) - 14px);
  }

  .requests, .calendar, .control {
    padding: 35px 30px 40px;
  }

  /* preview */
  .preview {
    flex-basis: calc(percentage(255 / 1172) - 14px);
    padding: 35px 30px 100px;
  }

  .preview__wrapper {
    min-height: 100%;
  }

  .preview__navigation {
    right: auto;
    top: auto;
    left: 30px;
    bottom: 40px;
    font-size: 14px;

    &::before, &::after {
      top: 8.5px;
    }
  }

  .preview__info {
    display: block;
  }

  .preview__avatar {
    width: 100px;
    margin-bottom: 30px;
  }

  .preview__user-data {
    flex-direction: column;
    gap: 20px;
  }

  /* requests */
  .requests {
    flex-basis: calc(percentage(337 / 889) - 14px);
  }

  /* calendar */
  .calendar {
    flex-basis: calc(percentage(552 / 889) - 14px);
  }

  /* control */
  .control {
    display: flex;
    align-items: center;
    border-radius: $border-radius;
  }

  .control__wrapper {
    flex-basis: 100%;
  }

  /* user */
  .user__form {
    padding: 40px;
  }

  .display-1200 {
    display: block;
  }

  /* skeleton */
  .skeleton {
    padding: 40px;
  }

  .preview__skeleton {
    display: block;
  }

  .preview__skeleton-wrapper {
    gap: 20px;
  }

  .preview__skeleton-avatar {
    margin-bottom: 20px;
  }

  .preview__skeleton-element {
    height: 50px;
  }

  .control__skeleton {
    flex-basis: 100%;
  }
}
</style>

<style>
.user__text-area {
  height: 110px;
  resize: none;
}
</style>