<template>
  <div class="text-area">
    <label class="text-area__label" :for="id">{{ label }}</label>
    <textarea
        class="text-area__area" :class="textAreaClassList"
        type="text" v-model="value" :id="id" :readonly="readonly"
        @input="update"
    ></textarea>
    <span class="text-area__close-icon" v-show="enableErase && modelValue" @click="erase(id)"></span>
  </div>
</template>

<script>
export default {
  name: 'AppTextArea',
  props: {
    modelValue: [String, Number],
    textAreaClassList: Array,
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    enableErase: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'erase'],
  data() {
    return {
      value: ''
    }
  },
  methods: {
    update(event) {
      this.$emit('update:modelValue', event.target.value)
    },

    erase(id) {
      this.value = ''
      this.$emit('erase', id)
    }
  },
  watch: {
    modelValue(newValue, oldValue) {
      if (newValue !== oldValue && this.value !== newValue) {
        this.value = newValue
      }
    }
  },
  created() {
    this.value = this.$props.modelValue
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.text-area {
  display: inline-block;
  position: relative;
}

.text-area__label {
  position: absolute;
  top: -6px;
  left: 20px;
  padding: 0 10px;
  font-size: 10px;
  color: $label-text-color;
  background-color: #fff;
}

.text-area__area {
  width: 100%;
  padding: 15px 40px 15px 20px;
  font-size: 13px;
  border: 1px solid $separator-color;
  border-radius: $border-radius;

  &:focus {
    outline: none;
  }
}

.text-area__close-icon {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  &::before, &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border: 0.5px solid $separator-color;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

@media screen and (min-width: 576px) {
  .text-area__label {
    font-size: 12px;
  }

  .text-area__area {
    font-size: 15px;
  }
}

@media screen and (min-width: 992px) {
  .text-area__label {
    font-size: 13px;
  }

  .text-area__area {
    font-size: 16px;
  }
}
</style>